.clock{

/*    Cool CSS*/

    background-color: #333;
    color: white;
    font-size: 48px;
    padding: 20px;
    border-radius: 10px;
    display: inline-block;
    margin: 10px;
    box-shadow: 0 0 10px black;
    text-align: center;
    width: 200px;
    height: 200px;
    line-height: 200px;
    font-family: 'Orbitron', sans-serif;
    font-weight: bold;
    text-shadow: 0 0 10px black;
    position: relative;


}
